import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import Projects from "../../../../src/collections/blog/2022/2022-01-07-Recap-KubeCon-China-TAG-Network-talk/projects.webp";
import Gnh from "../../../../src/collections/blog/2022/2022-01-07-Recap-KubeCon-China-TAG-Network-talk/gnh-meshery.webp";
import Smp from "../../../../src/collections/blog/2022/2022-01-07-Recap-KubeCon-China-TAG-Network-talk/smp-meshery.webp";
import Smi from "../../../../src/collections/blog/2022/2022-01-07-Recap-KubeCon-China-TAG-Network-talk/smi-meshery.webp";
import Patterns from "../../../../src/collections/blog/2022/2022-01-07-Recap-KubeCon-China-TAG-Network-talk/patterns.webp";
import Catalog from "../../../../src/collections/blog/2022/2022-01-07-Recap-KubeCon-China-TAG-Network-talk/catalog.webp";
import slidesTAG from "../../../../src/collections/events/2021/kubecon-china-2021/cncf-tag-network-and-service-mesh-working-group-kubecon-china-2021-lee-calcote-ken-owens.pdf";
import { Link } from "gatsby";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  Projects,
  Gnh,
  Smp,
  Smi,
  Patterns,
  Catalog,
  slidesTAG,
  Link,
  React
};