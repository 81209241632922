import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import slidesTAG from "../../../../src/collections/events/2021/kubecon-china-2021/cncf-tag-network-and-service-mesh-working-group-kubecon-china-2021-lee-calcote-ken-owens.pdf";
import { Link } from "gatsby";
import meshmark from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/meshmark-dark-text-side.svg";
import performanceQuestion from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/performance-question.webp";
import smp from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/smp.webp";
import meshmarkSlide from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/meshmark.webp";
import example from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/example.webp";
import formula from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/formula.webp";
import MUE from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/mue.webp";
import MeshMapDemo from "../../../../src/collections/blog/2022/2022-05-14-service-meshcon-talk-intel/meshmark-score.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  slidesTAG,
  Link,
  meshmark,
  performanceQuestion,
  smp,
  meshmarkSlide,
  example,
  formula,
  MUE,
  MeshMapDemo,
  React
};