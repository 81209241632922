import { BlogWrapper } from "../../../../src/collections/blog/Blog.style.js";
import Blockquote from "../../../../src/reusecore/Blockquote";
import slidesTAG from "../../../../src/collections/events/2021/kubecon-china-2021/cncf-tag-network-and-service-mesh-working-group-kubecon-china-2021-lee-calcote-ken-owens.pdf";
import { Link } from "gatsby";
import DDE from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hashicorp-talk/docker-extension-meshery.webp";
import developer from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hashicorp-talk/developers-need.webp";
import dashboard from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hashicorp-talk/dashboard.webp";
import designer from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hashicorp-talk/designer-1.webp";
import visualizer from "../../../../src/collections/blog/2022/2022-05-10-dockercon-22-hashicorp-talk/viz-1.webp";
import * as React from 'react';
export default {
  BlogWrapper,
  Blockquote,
  slidesTAG,
  Link,
  DDE,
  developer,
  dashboard,
  designer,
  visualizer,
  React
};