import { Link } from "gatsby";
import { ProgramsWrapper } from "../../../../src/collections/programs/Programs.style.js";
import hacktoberfest2022 from "../../../../src/collections/programs/hacktoberfest-2022/hacktoberfest2022.webp";
import hacktoberfest2022prepslides from "../../../../src/collections/events/2022/hacktoberfest-2022/hacktoberfest-prep-easing-into-cncf-open-source-projects.pdf";
import * as React from 'react';
export default {
  Link,
  ProgramsWrapper,
  hacktoberfest2022,
  hacktoberfest2022prepslides,
  React
};